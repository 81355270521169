import React, { useState } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Select from "react-select";
import { fetchVariations, updateVariation } from "../../services";
import { useQuery } from "react-query";
import { Formik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";

const EditVariation = () => {
    const { variation_id } = useParams()
    const {data: variation, isLoading} = useQuery("variation", () => fetchVariations(null, variation_id), {
        select: (data) => data.variations[0],
        refetchOnWindowFocus: false,
    })

  const submitUpdate = async (info) => {
    try {
      await updateVariation(info, variation.id);
      window.location.href = `/variations/${variation.product_id}`
    } catch (e) {
      console.log(e);
    }
  };

  if (isLoading) return (<>Loading...</>)

  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="content-header">
          {/* <div className="container"> */}
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Edit Variation (<i className="text-muted">{variation.sku} {variation.color}-{variation.size}</i>)</h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/products">Products</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href={`/variations/${variation.product_id}`}>Variations</a>
                  </li>
                  <li className="breadcrumb-item active">New variation</li>
                </ol>
              </div>
            </div>
          {/* </div> */}
        </div>

        <div className="content">
          {/* <div className="container"> */}
            <div className="row">
              <div className="col-md-12">
                <div className="card card-warning">
                  <div className="card-header">
                    <h3 className="card-title">Variation Info</h3>
                  </div>

                  <Formik
                    // initialValues={{ name_en: "", name_ar: "", dep_ids: [] }}
                    initialValues={{ color: variation.color, color_ar: variation.color_ar, size: variation.size, qty: variation.qty, old_price: variation.old_price, new_price: variation.new_price }}
                    onSubmit={(values, { setSubmitting }) => {
                        submitUpdate(values);
                        setSubmitting(false);
                    }}
                    validationSchema={Yup.object().shape({
                      color: Yup.string().required(),
                      color_ar: Yup.string().required(),
                      size: Yup.string().required(),
                      qty: Yup.number().required(),
                      old_price: Yup.number().required(),
                      new_price: Yup.number().required(),
                      /*dep_ids: Yup.string().required(
                        "Please select at least one department"
                      ),*/
                    })}
                  >
                    {(props) => {
                      const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        setFieldValue,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      } = props;

                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="card-body">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-lg-2">
                                  <label>Color</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Variation Color (EN)"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="color"
                                    value={values.color}
                                  />
                                </div>

                                <div className="col-lg-2">
                                  <label>Color (AR)</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Variation Color (AR)"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="color_ar"
                                    value={values.color_ar}
                                  />
                                </div>

                                <div className="col-lg-1">
                                  <label>Size</label>
                                  <input
                                    // type="number"
                                    type="text"
                                    className="form-control"
                                    placeholder="Size"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="size"
                                    value={values.size}
                                  />
                                </div>

                                <div className="col-lg-2">
                                  <label>Old price</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Old Price (xx.xx)"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="old_price"
                                    value={values.old_price}
                                  />
                                </div>

                                <div className="col-lg-2">
                                  <label>New price</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="New Price (xx.xx)"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="new_price"
                                    value={values.new_price}
                                  />
                                </div>

                                <div className="col-lg-2">
                                  <label>Quantity</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Quantity"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="qty"
                                    value={values.qty}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card-footer">
                            <button type="submit" disabled={isSubmitting} className="btn btn-warning">
                              Update variation
                            </button>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default EditVariation;
