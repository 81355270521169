import CircularProgress from '@mui/material/CircularProgress';

const Spinner = () => {
    return (
        <div className="is-flex aic jcc">
            <CircularProgress size="3rem" />
        </div>
    )
}

export default Spinner