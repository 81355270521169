import React, { useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { useQuery } from "react-query";
import { Formik } from "formik";
import { fetchProducts } from "../../services";
import Spinner from "../../components/Spinner";
import Select from "react-select"
import { removeFilters } from "../../utils";

const Products = () => {
  const [filters, setFilters] = useState({})
  const { data: products, isLoading } = useQuery(
    "products",
    () => fetchProducts({}),
    {
      select: (data) => {
        return data.products;
      },
      refetchOnWindowFocus: false
    }
  );

  const productColumns = [
    {
      field: "sku",
      headerName: "SKU",
      headerClassName: 'super-app-theme--header',
      flex: 0.1
    },
    {
      field: "supplier_name_en",
      headerName: "Supplier",
      headerClassName: 'super-app-theme--header',
      flex: 0.15
    },
    {
      field: "description_en",
      headerName: "Description",
      headerClassName: 'super-app-theme--header',
      flex: 0.25
    },
    {
      // field: "total_sales",
      headerName: "Category",
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return <span>{params.row.department_name_en} {params.row.category_name_en}</span>
      },
      flex: 0.3
    },
    {
      field: "actions",
      headerClassName: 'super-app-theme--header',
      flex: 0.15,
      headerName: "Actions",
      renderCell: (params) => {
        return (
          <>
            <a href={`/edit-product/${params.row.id}`} className="btn btn-sm btn-default mr-2">
              <i className="fa fa-pencil"></i>
            </a>

            <a href={`/variations/${params.row.id}`} className="btn btn-sm btn-primary mr-2">
              <i className="fa fa-eye"></i>
            </a>

            <button className="btn btn-sm btn-success">
              <i className="fa fa-archive"></i>
            </button>
          </>
        )
      }
    }
  ]

  if (isLoading) {
    return (
      <div className="is-flex aic jcc">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="content-header">
          {/* <div className="container"> */}
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Products <span className="text-muted text-sm">({products?.length} results found)</span></h1>
            </div>

            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                {/* <li className="breadcrumb-item active">Dashboard</li> */}
              </ol>
            </div>
          </div>
          {/* </div> */}
        </div>

        <div className="content">
          {/* <div className="container"> */}
          <div className="row mb-4">
            <div className="col-lg-3">
              <a href="/new-product" className="btn btn-primary btn-sm">
                New product
              </a>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-3">
              <input
                type="text"
                className="form-control"
                placeholder="Filter by SKU"
                onChange={({ target }) => {
                  setFilters({ ...filters, sku: target.value})
                }}
              />
            </div>

            <div className="col-lg-3">
              <Select
                options={[]}
                isClearable
                placeholder="Filter by Supplier"
                defaultValue={filters?.supplier_id}
                onChange={( value, triggeredAction ) => {
                  value && value.value && setFilters({...filters, supplier_id: value.value})

                  if (triggeredAction.action == "clear") {
                    setFilters(removeFilters(["supplier_id"], filters))
                  }
                }}
              />
            </div>

            <div className="col-lg-3">
              <Select
                options={[]}
                isClearable
                placeholder="Filter by Department"
                defaultValue={filters?.department_id}
                onChange={( value, triggeredAction ) => {
                  value && value.value && setFilters({...filters, department_id: value.value})

                  if (triggeredAction.action == "clear") {
                    setFilters(removeFilters(["department_id"], filters))
                  }
                }}
              />
            </div>

            <div className="col-lg-3">
              <Select
                options={[]}
                isClearable
                placeholder="Filter by Category"
                defaultValue={filters?.category_id}
                onChange={( value, triggeredAction ) => {
                  value && value.value && setFilters({...filters, category_id: value.value})

                  if (triggeredAction.action == "clear") {
                    setFilters(removeFilters(["category_id"], filters))
                  }
                }}
              />
            </div>
          </div>

          <Box sx={{ maxHeight: "100vh", backgroundColor: "#FFF" }}>
            <DataGrid
              rows={products}
              columns={productColumns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
            />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Products;
