import Cookies from "js-cookie"

const getToken = () => {
    return Cookies.get("admintoken")
}

const setToken = (name, opts) => {
    Cookies.set(name, `${opts.value}`)
}

const removeFilters = (keys, filters) => {
    let filtersClone = {...filters}

    keys.map((key) => {
        delete filtersClone[key]
    })
    
    return filtersClone
}

const signout = () => {
    Cookies.remove("admintoken")
    window.location.href = process.env.REACT_APP_URL
}

const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export { getToken, setToken, signout, capitalize, removeFilters }